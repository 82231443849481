.page-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.circle {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;  /* Make the circle clickable */
    transition: background-color 0.3s;
}

.circle:hover {
    background-color: #007bff;  /* Hover color to show interactivity */
}

.circle.active {
    background-color: #007bff;  /* Highlight the active step */
}