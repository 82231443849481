.Basics {
    align-self: center;
    max-width: 700px;
    margin: 50px auto;
    padding: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f9ff;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Basics h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 1.8em;
}

.Basics form {
    display: flex;
    flex-direction: column;
    width: 90%;
}

/* Layout for First Name and Last Name in the same row */
.Basics .name-row {
    display: flex;
    justify-content: space-between;
    gap: 40px; /* Add spacing between fields */
}

.Basics .name-row .form-group {
    flex: 1;
}

/* Individual form labels and inputs */
.Basics label {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.1em;
    font-weight: 600;
}

.Basics input,
.Basics select {
    width: 100%;
    padding: 12px;
    border: 1px solid #bcd4e6;
    border-radius: 6px;
    font-size: 1.1em;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.Basics input:focus,
.Basics select:focus {
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
    outline: none;
}

.Basics button {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.Basics button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

.Basics .form-group {
    margin-bottom: 20px;
}

/* Error messages */
.Basics .error {
    color: red;
    font-size: 0.9em;
    margin-top: -10px;
}

@media (max-width: 600px) {
    .Basics {
        padding: 20px;
    }

    .Basics h1 {
        font-size: 1.5em;
    }

    .Basics button {
        font-size: 1em;
    }

    /* Stack First and Last Name on top of each other on small screens */
    .Basics .name-row {
        flex-direction: column;
    }
}