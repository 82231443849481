.Details {
    max-width: 700px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f0f9ff;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90vw;
    gap: 20px;
}

.Details h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 1.8em;
}

.Details form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: flex;
    flex-direction: column;
    color: #555;
    font-size: 1.1em;
    margin-bottom: 5px;
}

textarea,
select {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1.1em;
}

textarea:focus,
select:focus {
    border-color: #66afe9;
    outline: none;
}

/* Custom checkboxes in the same row */
.checkbox-group {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.1em;
    position: relative;
    padding-left: 35px;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ccc;
    transition: background-color 0.3s ease;
}

.custom-checkbox input:checked + .checkmark {
    background-color: #007bff;
}

.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked + .checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* Submit Button */
.submit-button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}

.uploaded-files {
    margin-top: 10px;
    background-color: #f9f9f9; /* Light background to differentiate the section */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    max-height: 150px; /* Limit the height */
    overflow-y: auto; /* Allow scrolling if there are many files */
}

.uploaded-files span {
    display: block; /* Display each file name on a new line */
    font-size: 14px;
    padding: 5px 0;
    color: #333;
    border-bottom: 1px solid #e5e5e5; /* A subtle border to separate file names */
}

.uploaded-files span:last-child {
    border-bottom: none; /* Remove border from last item */
}

.uploaded-files span:hover {
    background-color: #f1f1f1; /* Highlight each file name on hover */
}

.uploaded-files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.image-preview {
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.image-buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Adds space between buttons */
}

.image-buttons-container label {
    display: inline-block;
    position: relative;
    padding: 15px;
    text-align: center;
    background-color: #575757; /* Blue button color */
    color: white;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    /* max-width: 180px; */
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.image-buttons-container label:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.image-buttons-container label .icon {
    font-size: 24px;
    margin-bottom: 10px;
}

.image-buttons-container label input {
    display: none; /* Hide the input field */
}

.image-buttons-container label p {
    margin: 0;
    font-weight: bold;
}

.upload-button {
    background-color: #28a745; /* Green background for upload */
}

.upload-button:hover {
    background-color: #218838; /* Darker green on hover */
}

.camera-button {
    background-color: #ffc107; /* Yellow background for camera */
}

.camera-button:hover {
    background-color: #e0a800; /* Darker yellow on hover */
}
