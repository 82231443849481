.Location {
    max-width: 700px;
    margin: 50px auto;
    padding: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f9ff;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Location h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 1.8em;
}

.map-outer {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
}

.map-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    border: 2px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
}

.confirm-button {
    margin-top: 20px;
    padding: 12px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.confirm-button:hover {
    background-color: #218838;
    box-shadow: 0 4px 12px rgba(40, 167, 69, 0.3);
}

.address-form {
    margin-top: 20px;
    width: 100%;
}

.address-form p {
    margin-bottom: 20px;
    color: #555;
    font-size: 1.1em;
    line-height: 1.5;
}

.address-form form {
    display: flex;
    flex-direction: column;
}

.address-form .form-group {
    margin-bottom: 20px;
}

.address-form label {
    display: flex;
    flex-direction: column;
    color: #333;
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 10px;
}

.address-form input {
    padding: 12px;
    border: 1px solid #bcd4e6;
    border-radius: 6px;
    font-size: 1.1em;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.address-form input:focus {
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
    outline: none;
}

.address-form button {
    padding: 12px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.address-form button:hover {
    background-color: #0069d9;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
    .Location {
        padding: 20px;
    }

    .Location h1 {
        font-size: 1.5em;
    }

    .confirm-button {
        font-size: 1em;
    }

    .address-form input {
        font-size: 1em;
        padding: 10px;
    }

    .address-form button {
        font-size: 1em;
        padding: 10px;
    }
}

/* Spinner styles */
.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    text-align: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.button-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
}

button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

button:focus {
    outline: none;
}

button:first-of-type {
    background-color: #6c757d;
}

button:first-of-type:hover {
    background-color: #5a6268;
}