.Final {
    max-width: 700px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f0f9ff;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Final h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.Final p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.5;
}

@media (max-width: 600px) {
    .Final {
        padding: 20px;
    }

    .Final h1 {
        font-size: 1.6em;
    }

    .Final p {
        font-size: 1.1em;
    }
}