.Review {
    max-width: 700px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f0f9ff;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start   ;
}

.Review h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 1.8em;
}

.verification {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.verification h2 {
    color: #007bff;
    font-size: 1.5em;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.verification p {
    font-size: 1.1em;
    color: #555;
    margin: 10px 0;
}

.verification p strong {
    color: #333;
}

.review-section {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.review-section h2 {
    color: #007bff;
    font-size: 1.5em;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.review-section p {
    font-size: 1.1em;
    color: #555;
    margin: 10px 0;
}

.review-section p strong {
    color: #333;
}

.button-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

button:focus {
    outline: none;
}

button:first-of-type {
    background-color: #6c757d;
}

button:first-of-type:hover {
    background-color: #5a6268;
}


/* General styling for review sections */
.review-section {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #ddd;
}

/* Header Styling */
.review-section h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
}

/* Checkbox container styling */
.nice-checkbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: #333;
}

/* Checkbox Styling */
.nice-checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    accent-color: #007bff; /* Blue checkboxes */
    cursor: pointer;
}

/* Checkbox Label Styling */
.nice-checkbox label {
    cursor: pointer;
    user-select: none;
}

/* Legal disclaimer section specific styling */
#legalDisclaimerSection {
    width: 100%;
    background-color: #fff4f4;
    padding: 15px;
    border: 1px solid black;
    border-radius: 8px;
}

/* Data sharing section specific styling */
#dataSharingSection {
    width: 100%;
    background-color: #fff4f4;
    padding: 15px;
    border: 1px solid black;
    border-radius: 8px;
}

/* Required checkbox specific styling */
#dataSharingConsent, 
#privacyPolicyAgreement,
#legalDisclaimerAgreement {
    accent-color: #ff4d4d; /* Red accent for required checkboxes */
}

/* Extra space between sections */
.form-section {
    margin-bottom: 20px;
}



/* Responsive Design for smaller screens */
@media (max-width: 600px) {
    .Review {
        padding: 20px;
    }

    .Review h1 {
        font-size: 1.6em;
    }

    .review-section h2 {
        font-size: 1.3em;
    }

    button {
        width: 100%;
        margin-bottom: 10px;
    }

    .button-row {
        flex-direction: column;
    }
}