.Home {
    max-width: 700px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f0f9ff;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 1.8em;
}

.Home p {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 30px;
}

.button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-top: 20px;
    gap: 10px;
}

.auth-button {
    padding: 12px 20px;
    font-size: 1.1em;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    /* width: 200px; */
    height: 50px;
    color: white; /* Ensure the text is visible on the image */
    background-image: url('https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/signin.svg'); /* Add your image here */
    background-size: cover; /* Ensures the image covers the button area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat;
    /* display: flex; */
    /* justify-content: center;
    align-items: center;
    text-align: center; */
}

.auth-button:hover {
    background-color: rgba(0, 123, 255, 0.8); /* Optional hover effect to darken the background */
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

button:focus {
    outline: none;
}

button:first-of-type {
    background-color: #6c757d;
}

button:first-of-type:hover {
    background-color: #5a6268;
}

/* Responsive Design for smaller screens */
@media (max-width: 600px) {
    .Home {
        padding: 20px;
    }

    .Home h1 {
        font-size: 1.6em;
    }

    .Home p {
        font-size: 1em;
    }

    button {
        width: 100%;
        margin-bottom: 10px;
    }

    .button-group {
        flex-direction: column;
    }
}